<template>
  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="12"
          >
            <b-row class="breadcrumbs-top">
              <b-col cols="12">
                <h1
                  class="custom-header-title"
                >
                  {{ request.stringID }} - {{ request.agent ? request.agent.name : '' }}
                </h1>
                <p class="log_info">
                  Created on {{ dateFormatWithTime(request.createdAt) }}<br>Last updated <span v-if="request.approvedBy">by {{ request.approvedBy.name }}</span> on {{ dateFormatWithTime(request.updatedAt) }}
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <section class="invoice-preview-wrapper">
      <b-row class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
        >
          <div
            class="user_block ac_type mb-2"
            style="float: none;"
          >
            <div class="user_avatar" />
            <div class="user_info">
              <h6>Status</h6>
              <h5 class="text-capitalize">
                {{ request.status }}
              </h5>
            </div>
          </div>
          <div
            v-if="request.status == 'rejected'"
            class="reject_not"
          >
            <b-card>
              <template #header>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-primary"
                  class="mr-3"
                >
                  <feather-icon
                    icon="MessageSquareIcon"
                    class=""
                    size="20"
                  />
                </b-button>
                <h3 class="align-middle mr-auto">
                  Remarks
                </h3>
              </template>
              <b-card-text
                class="mb-0"
              >
                <strong>{{ request.approvedBy.name }} has rejected this Agent Request.</strong>
                <span class="notetext">
                  {{ request.rejectNote }}
                </span>
              </b-card-text>
            </b-card>
          </div>
          <b-card v-if="request.status == 'awaiting approval' || request.status == 'pending'">
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  class="white-color"
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                Ready to Approve?
              </h3>
            </template>
            <b-form-checkbox
              v-model="secondAgree"
              value="accepted"
              unchecked-value=""
              class="custom-control-primary"
            >
              I have verified the requested changes and the attached documents.
            </b-form-checkbox>

            <!-- Button: Send Invoice -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mb-75"
              block
              :disabled="secondAgree == '' || !canViewThisAction('approve', 'AgentRequest')"
              @click="approveRequest()"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Approve</span>
            </b-button>
            <b-button
              v-if="canViewThisAction('reject', 'AgentRequest')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-danger"
              class="mb-75"
              block
              @click="rejectRequest()"
            >
              <feather-icon
                icon="XIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Reject</span>
            </b-button>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <b-card
            no-body
            class=""
            title="Agent Requested Changes"
          >
            <b-card-body>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <div class="d-flex mb-2">
                    <b-card-title class="custom-header-title fs-21">
                      Agent Requested Changes
                    </b-card-title>
                    <b-button
                      v-if="request.status == 'awaiting approval'"
                      variant="link"
                      class="btn-icon rounded-circle ml-auto mr-1 flat-primary-bg"
                      @click="openEditModal"
                    >
                      <feather-icon icon="EditIcon" /> Edit
                    </b-button>
                  </div>
                  <b-table-simple
                    borderless
                    responsive
                    class="particulars_of_deceased small-padding-table d-none d-md-block"
                  >
                    <b-tbody>
                      <template v-if="request.changeType == 'Card Display Name'">
                        <b-tr>
                          <b-th>
                            Card Display Name
                          </b-th>
                          <b-td>
                            <del class="">{{ request.prevDisplayName || '-' }}</del>
                          </b-td>
                          <td>
                            <feather-icon
                              icon="ArrowRightIcon"
                              size="18"
                              class="align-middle text-body"
                            />
                          </td>
                          <b-td>
                            <span class="">{{ request.displayName || '-' }}</span>
                          </b-td>
                        </b-tr>
                      </template>
                      <template v-if="request.changeType == 'Bank Details'">
                        <b-tr>
                          <b-th>
                            Bank Details
                          </b-th>
                          <b-td>
                            <del class="">{{ request.prevBankCode || '-' }} - {{ request.prevBankName || '-' }}</del>
                          </b-td>
                          <td>
                            <feather-icon
                              icon="ArrowRightIcon"
                              size="18"
                              class="align-middle text-body"
                            />
                          </td>
                          <b-td>
                            <span class="">{{ request.bankCode || '-' }} - {{ request.bankName || '-' }}</span>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th />
                          <b-td>
                            <del class="">{{ request.prevBankBranch || '-' }}</del>
                          </b-td>
                          <td>
                            <feather-icon
                              icon="ArrowRightIcon"
                              size="18"
                              class="align-middle text-body"
                            />
                          </td>
                          <b-td>
                            <span class="">{{ request.bankBranch || '-' }}</span>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th />
                          <b-td>
                            <del class="">{{ request.prevBankAccountNo || '-' }}</del>
                          </b-td>
                          <td>
                            <feather-icon
                              icon="ArrowRightIcon"
                              size="18"
                              class="align-middle text-body"
                            />
                          </td>
                          <b-td>
                            <span class="">{{ request.bankAccountNo || '-' }}</span>
                          </b-td>
                        </b-tr>
                      </template>
                    </b-tbody>
                  </b-table-simple>

                  <hr>

                  <div class="mb-2 mt-3">
                    <b-card-title class="custom-header-title fs-21">
                      Supporting Documents
                    </b-card-title>
                    <p>Review the Proof of Receipt to confirm that the agent has paid for the changes to the card display name.</p>
                    <b-img
                      v-if="request.attachments && request.attachments.length"
                      class="mb-1 mb-sm-0 user__avatar cursor-pointer"
                      height="160"
                      :src="request.attachments[0]"
                      @click="$bvModal.show('modal-attachment-view')"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>

    <b-modal
      id="modal-attachment-view"
      size="lg"
      title=""
      centered
      no-stacking
      no-footer
      body-class="dark-body"
      header-class="front-modal custom-close"
    >
      <b-img
        v-if="request.attachments && request.attachments.length"
        :src="request.attachments[0]"
        fluid
        alt="Responsive image"
      />
    </b-modal>

    <b-modal
      id="modal-update-agent"
      ref="update_agent_modal"
      modal-class="status-update-modal iu-modal"
      footer-class="iu-edit-modal-footer"
      cancel-variant="flat-primary"
      ok-variant="primary"
      ok-title="Save Changes"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Edit"
      size="sm"
      @ok="submitEditForm"
    >
      <b-form @submit.prevent="submitEditForm">
        <validation-observer
          ref="agentEditForm"
        >
          <b-form-group
            v-if="request.changeType == 'Card Display Name'"
            label="Card Display Name"
            label-for="h-iu-displayName"
          >
            <b-form-input
              id="h-iu-displayName"
              v-model="displayName"
              placeholder="Card Display Name"
              name="displayName"
            />
          </b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Bank Name"
            vid="bankName"
            rules="required"
          >
            <b-form-group
              v-if="request.changeType == 'Bank Details'"
              label="Bank Name*"
              label-for="h-iu-bankName"
            >
              <v-select
                id="h-iu-bankName"
                v-model="bankName"
                :options="bankOptions"
                :clearable="false"
                placeholder="Select a country"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <b-form-group
            v-if="request.changeType == 'Bank Details'"
            label="Bank Code"
            label-for="h-iu-bankCode"
          >
            <b-form-input
              id="h-iu-bankCode"
              v-model="bankCode"
              placeholder="Bank Code"
              name="bankCode"
            />
          </b-form-group>
          <b-form-group
            v-if="request.changeType == 'Bank Details'"
            label="Bank Branch"
            label-for="h-iu-bankBranch"
          >
            <b-form-input
              id="h-iu-bankBranch"
              v-model="bankBranch"
              placeholder="Bank Branch"
              name="bankBranch"
            />
          </b-form-group>
          <b-form-group
            v-if="request.changeType == 'Bank Details'"
            label="Bank Account No.*"
            label-for="h-iu-bankAccountNo"
          >
            <validation-provider
              #default="{ errors }"
              name="Account No."
              vid="bankAccountNo"
              rules="required"
            >
              <b-form-input
                id="h-iu-bankAccountNo"
                v-model="bankAccountNo"
                placeholder="Bank Account No."
                name="bankAccountNo"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          <span class="align-middle">Save Changes</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTr, BTh, BTd, BTbody, BImg, VBTooltip, BForm,
  BCardText, BFormCheckbox, BCardBody, BCardTitle, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import store from '@/store/index'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTr,
    BTd,
    BTh,
    BTbody,
    BImg,
    BCardText,
    BFormCheckbox,
    BCardBody,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      rejectNote: '',
      secondAgree: '',
      displayName: '',
      bankCode: '',
      bankName: '',
      bankBranch: '',
      bankAccountNo: '',
      bankOptions: ['DBS / POSB', 'UOB'],
      required,

      request: {},
      relatedFiles: [],
    }
  },
  created() {
    this.$http.get(`customer/agent/profile-change/${this.$route.params.id}/show`)
      .then(response => {
        this.request = response.data.data
        this.displayName = this.request.displayName
        this.bankCode = this.request.bankCode
        this.bankName = this.request.bankName
        this.bankBranch = this.request.bankBranch
        this.bankAccountNo = this.request.bankAccountNo
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    approveRequest() {
      this.$swal({
        title: 'Approve this Agent Request?',
        html: 'You are going to approve this Agent Request. Are you sure?',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/success.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, cancel',
        confirmButtonText: 'Yes, proceed',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.updateChange('approved')
          }
        })
    },

    rejectRequest() {
      this.$swal({
        title: 'Are you sure you want to <br>reject this request?',
        html: `Once you reject this request <strong>${this.request.stringID}</strong>, <br>agent will be informed.`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/file-cross.png'),
        input: 'textarea',
        inputPlaceholder: 'Add Remarks',
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, reject request',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
          input: 'reject-note-ta',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.rejectNote = result.value
            this.updateChange('rejected')
          }
        })
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },

    updateChange(status) {
      let title = ''
      if (status === 'approved') {
        title = 'Change Approved'
      } else {
        title = 'Change Rejected'
      }
      const formData = new FormData()
      formData.append('status', status)
      formData.append('rejectNote', this.rejectNote)

      this.$http.post(`customer/agent/profile-change/${this.$route.params.id}/approve`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.request = response.data.data
          this.$swal({
            title,
            html: '',
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/icons/success.png'),
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: 'Custom Icon',
            showCancelButton: false,
            confirmButtonText: 'Okay',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    async openEditModal() {
      this.$refs.update_agent_modal.show()
      await this.$nextTick()
      this.displayName = this.request.displayName
      this.bankCode = this.request.bankCode
      this.bankName = this.request.bankName
      this.bankBranch = this.request.bankBranch
      this.bankAccountNo = this.request.bankAccountNo
    },
    submitEditForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.agentEditForm.validate().then(success => {
        if (success) {
          this.$refs.update_agent_modal.hide()
          this.$swal({
            title: 'Save current changes?',
            html: 'Your changes will overwrite the current information, are you sure?',
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/icons/warning.png'),
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: 'Custom Icon',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: 'Yes, Save Changes',
            cancelButtonText: 'No, go back',
            customClass: {
              confirmButton: 'btn btn-primary ml-1',
              cancelButton: 'btn btn-outline-primary',
            },
            buttonsStyling: false,
          })
            .then(result => {
              if (result.value) {
                const formData = new FormData()
                formData.append('request', this.request._id)
                formData.append('changeType', this.request.changeType)
                formData.append('agent', this.request.agent._id)
                formData.append('status', 'approved')
                formData.append('displayName', this.displayName)
                formData.append('bankCode', this.bankCode)
                formData.append('bankName', this.bankName)
                formData.append('bankBranch', this.bankBranch)
                formData.append('bankAccountNo', this.bankAccountNo)

                this.$http.post('customer/agent/edit/overwrite', formData, {
                  headers: { 'Content-Type': 'multipart/form-data' },
                })
                  .then(response => {
                    if (response.data.success) {
                      this.request = response.data.data
                      this.$swal({
                        title: 'Changes Saved',
                        html: '',
                        // eslint-disable-next-line global-require
                        imageUrl: require('@/assets/images/icons/success.png'),
                        imageWidth: 80,
                        imageHeight: 80,
                        imageAlt: 'Custom Icon',
                        showCancelButton: false,
                        confirmButtonText: 'Okay',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                      })
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
                  .catch(error => {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
  .per-page-selector {
    width: 90px;
  }
  .badge.light-font {
    font-weight: 400;
  }
  .modal-header.payment-status.front-modal {
    background: #21BB8B;
    padding: 24px 30px !important;
    button {
      background: rgba(255, 255, 255, 0.3) !important;
      margin-right: 20px !important;
      padding: 0;
      border-radius: 5px;
      min-width: 40px;
      width: 40px;
      line-height: 40px;
      height: 40px;
      position: relative;
    }
    h3 {
      font-size: 18px;
      line-height: 21px;
      color: #fff;
    }
    span.step-class {
      color: #fff !important;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 23px;
      font-size: 12px;
      display: block;
    }
    .feather {
      vertical-align: text-bottom;
      color: #fff;
    }
  }
  .btn-success-without-button {
    border-color: #21BB8B !important;
    background-color: #21BB8B !important;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
